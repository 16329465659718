import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import RemarkModal from './RemarkModal';
import { GetCountAPI } from '../../../../../../utils/API/GetCount/GetCountAPI';
import { roop_CartCount } from '../../../Recoil/atom';
import { useSetRecoilState } from 'recoil';
import noImageFound from "../../../Assets/image-not-found.jpg"
import Cookies from "js-cookie";
import { formatter } from '../../../../../../utils/Glob_Functions/GlobalFunction';

const CartItem = ({
  item,
  diamondValue,
  index,
  CartCardImageFunc,
  onSelect,
  isSelected,
  selectedItem,
  multiSelect,
  onRemove,
  handleRemarkChange,
  handleSave,
}) => {
  const [imageSrc, setImageSrc] = useState(noImageFound);
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState(item?.Remarks || '');
  const [isSelectedItems, setIsSelectedItems] = useState();
  const setCartCountVal = useSetRecoilState(roop_CartCount)
  const [storeInitData, setStoreInitData] = useState();
  const visiterId = Cookies.get('visiterId');

  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMediumScreen = useMediaQuery('(min-width: 1038px) and (max-width: 1599px)');
  const isMobileScreen = useMediaQuery('(min-width: 320px) and (max-width: 1000px)');

  const loginInfo = JSON.parse(sessionStorage.getItem("loginUserDetail"));

  useEffect(() => {
    const storeinitData = JSON.parse(sessionStorage.getItem('storeInit'));
    setStoreInitData(storeinitData)
  }, [])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemarkChangeInternal = (e) => {
    setRemark(e.target.value);
    handleRemarkChange(e);
  };

  const handleSaveInternal = () => {
    handleSave(item, remark);
    handleClose();
  };

  useEffect(() => {
    handleIsSelected()
  }, [isSelected])

  const handleIsSelected = () => {
    let isselected = selectedItem?.id == item?.id
    setIsSelectedItems()
  }


  const handleRemoveItem = async (item) => {
    const returnValue = await onRemove(item);
    if (returnValue?.msg == "success") {
      GetCountAPI(visiterId).then((res) => {
        setCartCountVal(res?.cartcount);
      })
    }
  };


  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  }

  useEffect(() => {
    if (item?.ImageCount > 0) {
      CartCardImageFunc(item).then((src) => {
        setImageSrc(src);
      });
    } else {
      setImageSrc(noImageFound);
    }
  }, [item]);

  const diamondData = diamondValue?.find((dia) => dia?.stockno == item?.Sol_StockNo);


  return (
    <>
      <div className="rJ_cartMain-item" onClick={() => onSelect(item)}
        style={{
          // boxShadow: !multiSelect && !isMobileScreen && selectedItem?.id == item?.id && '0 3px 8px rgba(223, 100, 126, 0.54)'
          boxShadow: "none",
          border: !multiSelect && !isMobileScreen && selectedItem?.id == item?.id && '1px solid #7d7f85'
        }}
      >
        <div className="rJ_cart-item">
          <div className="rJ_cart-item__image">
            <img src={imageSrc} alt='Product-image' />
          </div>
          <div className="rJ_cart-item__details">
            <h3>{item?.designno != "" && item?.designno}
              {item?.TitleLine != "" && " - " + item?.TitleLine}</h3>
            <p>{item?.productDescription}</p>
            {/* {item?.sku != "" &&
            <p>SKU: {item?.sku}</p>
          } */}
            <div className="rJ_weightsContainer">
              {storeInitData?.IsGrossWeight == 1 &&
                <div className="rJ_weightPair">
                  <span className="rJ_weightLabel">Gwt:</span>
                  <span className="rJ_weightValue">{(item?.Gwt || 0)?.toFixed(3)}</span>
                </div>
              }
              {Number(item?.Nwt) !== 0 && (
                <div className="rJ_weightPair">
                  <span className="rJ_pipe">|</span>
                  <span className="rJ_weightLabel">Nwt:</span>
                  <span className="rJ_weightValue">{(item?.Nwt || 0)?.toFixed(3)}{' '}</span>
                </div>
              )}
              {storeInitData?.IsDiamondWeight == 1 &&
                <>
                  {(item?.Dwt != "0" || item?.Dpcs != "0") &&
                    <div className="rJ_weightPair">
                      <span className="rJ_pipe">|</span>
                      <span className="rJ_weightLabel">Dwt:</span>
                      <span className="rJ_weightValue">{(item?.Dwt || 0)?.toFixed(3)} / {(item?.Dpcs || 0)}</span>
                    </div>
                  }
                </>
              }
              {storeInitData?.IsGrossWeight == 1 &&
                <>
                  {(item?.CSwt != "0" || item?.CSpcs != "0") &&
                    <div className="rJ_weightPair">
                      <span className="rJ_pipe">|</span>
                      <span className="rJ_weightLabel">Cwt:</span>
                      <span className="rJ_weightValue">{(item?.CSwt || 0)?.toFixed(3)} / {(item?.CSpcs || 0)}{' '}</span>
                    </div>
                  }
                </>
              }
            </div>
            <div style={{ display: 'flex' }}>
              <p className='rJ_ringSize'>Quantity: {item?.Quantity}</p>&nbsp;
              {(item?.Size != "" && item?.Size != undefined && item?.Size != null) &&
                <p className='rJ_ringSize'>Size: {item?.Size}</p>
              }
            </div>
            {/* <span className="rJ_change-size">CHANGE SIZE</span> */}
          </div>
          {storeInitData?.IsPriceShow == 1 &&
            <div className="rJ_cart-item__price">
              <p>{loginInfo?.CurrencyCode ?? storeInitData?.CurrencyCode}&nbsp;{formatter(item?.UnitCostWithMarkUp)}</p>
              <span className="rJ_price-excl-vat">(Excl. VAT)</span>
            </div>
          }
          <>
            {storeInitData?.IsPriceShow == 1 &&
              <div className="rJ_cart-item__total-price">
                <p>{loginInfo?.CurrencyCode ?? storeInitData?.CurrencyCode}&nbsp;{formatter(item?.FinalCost)}</p>
                <span className="rJ_price-excl-vat">(Excl. VAT)</span>
              </div>
            }
          </>
          <div className="rJ_cart-item__remove">
            <button className="rJ_remove-button" onClick={() => handleRemoveItem(diamondData, index)}>×</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;
